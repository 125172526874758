<template>
  <div class="content-wrapper">

    <section class="content-header p-1 px-2">
      <div class=" div-header container-fluid">
        <div class="row">
            <div class="col-sm-12 d-flex justify-content-between align-items-center p-2 ">
              <span></span>
              <h4 class="my-1">{{company.name}}</h4>
              <router-link to="/admin/integrations" class="btn btn-outline-secondary btn-sm mr-2" 
                title="Voltar Empresas com Integrações de Whatsapp">
                <i class="fa fa-arrow-left mx-1"></i>Integrações
              </router-link>
            </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="row">
        <div class="col-md-3">
         
        </div>
        <!-- /.col -->
        <div class="col-md-12">
          <div class="card ">
            <div class="card-header ">

              <h3 class="card-title">Id companhia: {{company.company_id}}</h3>
              <div class="card-tools">
                <div class="input-group input-group-sm">
                  <!-- <input type="text" class="form-control" placeholder="Integração?" />
                  <div class="input-group-append">
                    <div class="btn btn-default">
                      <i class="fas fa-search"></i>
                    </div> -->
                  <!-- </div> -->
                </div>
              </div>
              <!-- /.card-tools -->
            </div>
            <!-- /.card-header -->
            <div class="card-body p-0">
              
              <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>cid</th>
                            <th>container_id</th>
                            <th>telefone</th>
                            <th>qr_code</th>
                            <th>internet</th>
                            <th>status</th>
                            <th>ações</th>
                        </tr>
                    </thead>
                  <tbody>
                      <tr v-for="(integration, i) in company.integrations"  :key="i">
                          <td>{{integration.cid}}</td>
                          <td style="max-width: 250px">{{integration.container_id}}</td>
                          <td>{{integration.phone || "Telefone indefinido"}}</td>
                          <td >
                              <div class="btn-switch" :class="integration.qr_code_status == 1 ? 'btn-switch-on': 'btn-switch-off' ">
                                  <button class="btn-on-off btn btn-xs" :class="status(integration.qr_code_status).btn"
                                    @click="change_qrcode(integration)">
                                  {{ status(integration.qr_code_status).label }}
                              </button>
                              </div>
                          </td>
                          <td>
                              <div class="btn-switch" :class="integration.internet_status == 1 ? 'btn-switch-on': 'btn-switch-off' ">
                                <button class="btn-on-off btn btn-xs" :class="status(integration.internet_status).btn"
                                  @click="change_internet(integration)">
                                    {{ status(integration.internet_status).label }}
                                </button>
                              </div>
                          </td>
                          <td>
                              <i :class="status_integration(integration)"></i>
                          </td>
                          <td>
                              <button class="btn btn-outline-success btn-sm" @click="recreate_integration(integration)">
                                  recriar
                              </button>
                          </td>
                      </tr>
                  </tbody>
                </table>
                <!-- /.table -->
              </div>
              <!-- /.mail-box-messages -->
            </div>
            <!-- /.card-body -->
            
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {},
      id: this.$route.params.id,
      server_url: this.$route.params.server_url,
    };
  },

    methods: {
      getCompany() {
        this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
          `Bearer ${this.$store.getters.tokenJWT}`;
        this.$tallos_admin_api
          .get(`integrations/company/${this.id}?server_url=${this.server_url}`)
          .then(res => {
            this.company = res.data;
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro ao buscar integrações.'})
            this.$router.push({path: '/admin/integrations'})
          });
      },

      status_company(integrations) {
          let status_qrcode = integrations.map(integ => {
              return integ.qr_code_status
          })
          
          let status_qr_final = status_qrcode.reduce((acumulador ,sqr) => {
              return acumulador += sqr
          })

          let status_internet = integrations.map(integ => {
              return integ.internet_status
          })
          
          let status_net_final = status_qrcode.reduce((acumulador ,net) => {
              return acumulador += net
          })

          if(status_qr_final === 0 && status_net_final === 0) {
              return 'far fa-circle mr-1 text-danger'
          } else if((status_qr_final > 0 && status_qr_final < status_qrcode.length)
              && (status_net_final > 0 && status_net_final < status_internet.length)) {
              return 'far fa-circle mr-1 text-secondary'
          } else if((status_qr_final > 0 && status_qr_final === status_qrcode.length )
              && (status_net_final > 0 && status_net_final === status_internet.length)) {
              return 'far fa-circle mr-1 text-success'
          }

      },

      status_integration(integration) {
          if (integration.qr_code_status == 0 && integration.internet_status == 0) {
              return 'fa fa-circle mr-1 text-danger'
          } else if ((integration.qr_code_status == 0 && integration.internet_status == 1)
              || integration.qr_code_status == 1 && integration.internet_status == 0) {
              return 'fa fa-circle mr-1 text-secondary'
          } else {
              return 'fa fa-circle mr-1 text-success'
          }
      },

      status(integration) {
        if (integration ==  1 ) {
              return {
                  label: 'on',
                  btn: 'btn-success'
              }
          } else {
              return {
                  label: 'off',
                  btn: 'btn-danger'
              }
          }
      },

      change_qrcode(status) {
        this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
          `Bearer ${this.$store.getters.tokenJWT}`;
        const query = `company=${this.company.company_id}&cid=${status.cid}&operation=qr_code`
        this.$tallos_admin_api
          .put(`integrations/company?${query}`)
            .then(res => {
              this.getCompany()
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro!'})
          })
      },

      change_internet(status) {
        this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
          `Bearer ${this.$store.getters.tokenJWT}`;
        const query = `company=${this.company.company_id}&cid=${status.cid}&operation=internet`
        this.$tallos_admin_api
          .put(`integrations/company?${query}`)
            .then(res => {
              this.getCompany()
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro!'})
          })
      },

      recreate_integration(integraiton) {
        this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
          `Bearer ${this.$store.getters.tokenJWT}`;
        const query = `company=${this.company.company_id}&cid=${integraiton.cid}&operation=recreate`
        this.$tallos_admin_api
          .put(`integrations/company?${query}`)
            .then(res => {
              this.getCompany()
              this.$toasted.global.defaultSuccess({msg: 'Integração recriada com sucesso favor aguardar!'})
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro!'})
          })
      }
    },

  created() {
    this.getCompany();
    document.title = 'Tallos Admin - Detalhes Integrações '
  }
};
</script>

<style scoped>

.content-wrapper {
  background-color: rgba(170, 169, 169, 0);
}

.div-header {
    /* background-color: #fff; */
    padding: 5px;
    border: 1px solid rgba(0,0,0,.1);
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    margin-bottom: 5px;
    /* border-radius: 5px; */
}

.btn-switch-on {
    display: flex;
    justify-content: flex-end;
    background-color: #0001;
    border: 1px solid #0001;
    width: 40px;
    height: 20px;
    border-radius: 5px;
}

.btn-switch-off {
    display: flex;
    justify-content: flex-start;
    background-color: #0001;
    border: 1px solid #0001;
    width: 40px;
    height: 20px;
    border-radius: 5px;
}

.btn-on-off {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin:0px;
    width: 50%;
    height: 100%;
}
</style>